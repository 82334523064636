import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/src/MotoritOnline.Web/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/src/MotoritOnline.Web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/src/MotoritOnline.Web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/src/MotoritOnline.Web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/src/MotoritOnline.Web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/src/MotoritOnline.Web/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/src/MotoritOnline.Web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc from "/src/MotoritOnline.Web/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/src/MotoritOnline.Web/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _02_datadog_plugin_5kp92w_LwFr1i8f0uO07O_uIibeMXFQAoS0yhBQDCe4 from "/src/MotoritOnline.Web/app/plugins/02.datadog-plugin.ts";
import _03_global_error_handler_plugin_Hv_aTdY3yXIiYkSf_SuaAaJOj88ncCtnqeJ5qUqK_p4 from "/src/MotoritOnline.Web/app/plugins/03.global-error-handler-plugin.ts";
import apollo_plugin_qiB1Pm64O_CEUQidvSXJyFZl8tKo8KqmDHiKrCREVwg from "/src/MotoritOnline.Web/app/plugins/apollo-plugin.ts";
import apollo_repository_plugin_HQ6Wl2B3tXTfHorFNOqYJz7IXyCW_2qJsdgjnyJIwG8 from "/src/MotoritOnline.Web/app/plugins/apollo-repository-plugin.ts";
import feature_flags_A7oMv0hXRuvcFjwtQm8i6Oh_2gRVVLKjY0I7ppXS0Yg from "/src/MotoritOnline.Web/app/plugins/feature-flags.ts";
import mitt_JIz7P7qE1X2vtYIHm1jPLyY3mPdeNS4fJ9GIDcwzSXA from "/src/MotoritOnline.Web/app/plugins/mitt.ts";
import trackjs_plugin_4vfjVR6axJZwDdNY9Qylz8PZ2zzY8PyIuGcBtuNDgBU from "/src/MotoritOnline.Web/app/plugins/trackjs-plugin.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  nuxt_plugin_UFMCqFAmT_rT4NcMBSUgq5IuOGNlCK5IH_2sTbP0mGc,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4,
  _02_datadog_plugin_5kp92w_LwFr1i8f0uO07O_uIibeMXFQAoS0yhBQDCe4,
  _03_global_error_handler_plugin_Hv_aTdY3yXIiYkSf_SuaAaJOj88ncCtnqeJ5qUqK_p4,
  apollo_plugin_qiB1Pm64O_CEUQidvSXJyFZl8tKo8KqmDHiKrCREVwg,
  apollo_repository_plugin_HQ6Wl2B3tXTfHorFNOqYJz7IXyCW_2qJsdgjnyJIwG8,
  feature_flags_A7oMv0hXRuvcFjwtQm8i6Oh_2gRVVLKjY0I7ppXS0Yg,
  mitt_JIz7P7qE1X2vtYIHm1jPLyY3mPdeNS4fJ9GIDcwzSXA,
  trackjs_plugin_4vfjVR6axJZwDdNY9Qylz8PZ2zzY8PyIuGcBtuNDgBU
]