import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client/core';
import type { FetchResult, Observable } from '@apollo/client/core';
import { type ErrorResponse, onError } from '@apollo/client/link/error';
import { TrackJS } from 'trackjs';

declare module '#app' {
  interface NuxtApp {
    $apolloClient: ApolloClient<any>;
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const graphqlBaseUrl = config.public.graphqlBaseurl;
  const httpLink = createHttpLink({
    uri: graphqlBaseUrl,
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }: ErrorResponse): Observable<FetchResult> | void => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        TrackJS?.track(error);
      });
    }

    if (networkError) {
      TrackJS.track({
        networkError,
        operationName: operation?.operationName,
        variables: operation?.variables,
      });
    }
  });

  const isServer = typeof window === 'undefined';

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        PageContent: {
          // We need to use "route" as a key here since "id" is not unique for all the car object pages.
          keyFields: ['id', 'route'],
        },
        // Fix for branchPageBlocks as some branchPages use the same epi page.
        BranchGeneralInformationHeroBlock: {
          keyFields: ['branchName', 'id'],
        },
        BranchGeneralInformationBlock: {
          keyFields: ['branchName', 'id'],
        },
        BranchEmployeesBlock: {
          keyFields: ['branchName', 'salesPersons', 'technicians', 'id'],
        },
        SectionBlock: {
          keyFields: ['blocks', 'id'],
        },
        BranchFacilitiesBlock: {
          keyFields: ['facilities', 'id'],
        },
        DynamicListBlock: {
          keyFields: ['id', 'branchCode'],
        },
        BranchSeoBlock: {
          keyFields: ['id', 'seoNearbyText'],
        },
        Car: {
          keyFields: ['id', 'finance'],
          fields: {
            carText: {
              merge(_, incoming) {
                return incoming;
              },
            },
            department: {
              merge(_, incoming) {
                return incoming;
              },
            },
            generalInfo: {
              merge(_, incoming) {
                return incoming;
              },
            },
            finance: {
              merge(_, incoming) {
                return incoming;
              },
            },
            status: {
              merge(_, incoming) {
                return incoming;
              },
            },
          },
        },
        ProductHeadBlock: {
          keyFields: ['id', 'product', ['id'], 'variation', ['id']],
        },
        ProductSearchResult: {
          fields: {
            products: {
              merge(existing, incoming) {
                return existing;
              },
            },
          },
        },
      },
    }),
    ...(isServer ? { ssrMode: true } : { ssrForceFetchDelay: 100 }),
    link: ApolloLink.from([errorLink, httpLink]),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });

  // nuxtApp.vueApp.provide(DefaultApolloClient, apolloClient);
  return {
    // Using provide here allows Nuxt3 to generate typings
    provide: {
      apolloClient,
    },
  };
});
