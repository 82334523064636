// https://www.damirscorner.com/blog/posts/20200904-ErrorHandlingInNuxtjs.html

import { TrackJS } from 'trackjs';

export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const dev = import.meta.dev;

  nuxtApp.vueApp.config.errorHandler = (err: unknown, instance: ComponentPublicInstance | null, info: string) => {
    if (
      ((err instanceof Error && err.message?.toLowerCase()?.includes('hydration')) ||
        (err instanceof String && err?.toLowerCase()?.includes('hydration'))) &&
      !dev
    ) {
      return;
    } else if (route.path.indexOf('_nuxt') === -1) {
      console.warn('ERROR: ', err, info);
      TrackJS.track(err as object);
    }
  };

  nuxtApp.vueApp.config.warnHandler = (msg: string, instance: ComponentPublicInstance | null, trace: string) => {
    if (msg?.toLowerCase()?.includes('hydration') && !dev) {
      return;
    }

    console.warn('WARN: ', msg, trace);
  };
});
